export default {
  fonts: {
    main: "Noto Sans",
    secondary: "Rotobo",
    defaultSize: "16px",
  },
  colors: {
    primary: "#303033",
    secondary: "#3a3a3f",
    success: "#3dc372",
    danger: "#e44e56",
    warning: "#ff9e45",
    info: "#2d2e33",
    muted: "#b4b5ba",
    pale: "#d0d2d9",
    white: "#ffffff",
    black: "#000000",
    indigo: "#1b1e27",
  },
  breakpoints: {
    xs: "576px",
    sm: "640px",
    md: "960px",
    lg: "1200px",
    xl: "1600px",
  },
  spacing: {
    s0: "0",
    s1: "0.25rem",
    s2: "0.5rem",
    s3: "1rem",
    s4: "1.5rem",
    s5: "3rem",
  },
  shadows: {
    shadow0: "0px 2px 5px -2px rgba(0,0,0,0.75)",
  },
  navbar: {
    position: "space-between", //this is 'justify-content'
    fontsize: "13px",
    smfontsize: "15px",
  },
}
