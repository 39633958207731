import React from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import Axios from "axios"
import styled, { css } from "styled-components"
import Form from "react-bootstrap/Form"
import Alert from "react-bootstrap/Alert"
import Button from "react-bootstrap/Button"
import { Container, Row, Col } from "../components/styled"
import { Link } from "gatsby"

const ContactForm = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      thema: "",
      message: "",
      terms: "",
      honeypot: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .strict(true)
        .trim("Don't write spaces")
        .matches(
          /^[a-zA-Za-яА-Я]+/,
          "Do not use numbers or special characters. Upper and lower case letters only"
        )
        .min(3, "At least 3 characters")
        .max(20, "No more than 20 characters")
        .required("Required field"),
      email: Yup.string().email("Invalid email").required("Required field"),
      thema: Yup.string()
        .strict(true)
        .trim(
          "Do not use extra spaces at the beginning and at the end of the message"
        )
        .min(2, "At least 2 characters")
        .max(20, "No more than 20 characters")
        .required("Required field"),
      message: Yup.string()
        .strict(true)
        .trim(
          "Do not use extra spaces at the beginning and at the end of the message"
        )
        .min(10, "At least 10 characters")
        .max(300, "No more than 300 characters")
        .required("Required field"),
      terms: Yup.bool()
        .oneOf([true], "The terms of the OTP policy must be accepted")
        .required("Required field"),
      honeypot: Yup.bool().oneOf([false], "You shall not pass, bot!"),
    }),
    onSubmit: values => {
      Axios({
        method: "post",
        url: "https://formspree.io/f/mjvjpwge",
        data: {
          name: values.name,
          email: values.email,
          thema: values.thema,
          message: values.message,
          terms: values.terms,
        },
      })
        .then(function (response) {
          console.log(response)
        })
        .catch(function (error) {
          console.log(error)
        })
      document.getElementById("contactform").style.display = "none"
      document.getElementById("successmessage").style.display = "inline"
      formik.resetForm()
    },
  })
  return (
    <>
      <Container fluid="lg" id="contactform" className="px-2">
        <div className="p-2">
          I will happy to answer any questions that you have or provide you with
          an estimate.
          <br /> Just send a message in the form below with any questions that
          you may have.
        </div>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group as={Row} id="formHorizontalName">
            <Col sm={12}>
              <FormGroup
                id="name"
                name="name"
                type="text"
                placeholder="Name:"
                {...formik.getFieldProps("name")}
              />

              {formik.touched.name && formik.errors.name ? (
                <WarningMessage>{formik.errors.name}</WarningMessage>
              ) : formik.touched.name && !formik.errors.name ? (
                <WarningMessage success>OK</WarningMessage>
              ) : (
                <WarningMessage pale>
                  Your name without leading and trailing spaces
                </WarningMessage>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} id="formHorizontalEmail">
            <Col sm={12}>
              <FormGroup
                id="email"
                name="email"
                type="email"
                placeholder="Email:"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <WarningMessage>{formik.errors.email}</WarningMessage>
              ) : formik.touched.email && !formik.errors.email ? (
                <WarningMessage success>OK</WarningMessage>
              ) : (
                <WarningMessage pale>Enter your email</WarningMessage>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} id="formHorizontalThema">
            <Col sm={12}>
              <FormGroup
                id="thema"
                type="text"
                name="thema"
                placeholder="Subject:"
                {...formik.getFieldProps("thema")}
              />
              {formik.touched.thema && formik.errors.thema ? (
                <WarningMessage>{formik.errors.thema}</WarningMessage>
              ) : formik.touched.thema && !formik.errors.thema ? (
                <WarningMessage success>OK</WarningMessage>
              ) : (
                <WarningMessage pale>
                  Message subject without leading and trailing spaces
                </WarningMessage>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} id="formHorizontalMessage">
            <Col sm={12}>
              <FormGroupText
                as="textarea"
                name="message"
                placeholder="Message:"
                {...formik.getFieldProps("message")}
              />
              {formik.touched.message && formik.errors.message ? (
                <WarningMessage>{formik.errors.message}</WarningMessage>
              ) : formik.touched.message && !formik.errors.message ? (
                <WarningMessage success>OK</WarningMessage>
              ) : (
                <WarningMessage pale>
                  Message body without leading and trailing spaces
                </WarningMessage>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} id="formHorizontalTerms">
            <Col sm={12}>
              <FormCheck
                type="checkbox"
                label="I authorize the processing of personal data"
                {...formik.getFieldProps("terms")}
              />
              {formik.touched.terms && formik.errors.terms ? (
                <WarningMessage style={{ paddingLeft: ".45rem" }}>
                  {formik.errors.terms}
                </WarningMessage>
              ) : null}
            </Col>
          </Form.Group>
          <OhNoHoneyBot>
            <Form.Group as={Row} id="formHoneyPot" className="ohnohoneybot">
              <Col sm={12}>
                <FormCheck
                  tabIndex="-10"
                  label="Sweet honey for bots"
                  type="checkbox"
                  {...formik.getFieldProps("honeypot")}
                />
                {formik.touched.honeypot && formik.errors.honeypot ? (
                  <WarningMessage pale>
                    {formik.errors.honeypot}{" "}
                  </WarningMessage>
                ) : null}
              </Col>
            </Form.Group>
          </OhNoHoneyBot>

          <Form.Group as={Row}>
            <Col sm={12}>
              <ButtonSubmit type="submit">Submit</ButtonSubmit>
            </Col>
            <Col sm={12}>
              <p>
                By submitting this form, you agree to the website's{" "}
                <Link to="/policy">personal data processing policy</Link> and
                the storage of the information provided.
              </p>
            </Col>
          </Form.Group>
        </Form>
      </Container>
      <Container id="successmessage" style={{ display: "none" }}>
        <Alert variant="info">
          <Alert.Heading>Message has been sent</Alert.Heading>
          <p>
            Thank you for your message!
            <br />I will consider your request and will contact you as soon as
            possible.
            <br />
            If the message is of an advertising nature, or simply SPAM, then
            don't blame me, I can ignore it.
            <br />
            <br />
            See you later, <br />
            <br />
            <b>Andrey Bond.</b>
          </p>
        </Alert>
      </Container>
    </>
  )
}
export default ContactForm

const OhNoHoneyBot = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
`

const formProperties = css`
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  border: 0 none;
  border-bottom: 3px solid #e5e5e7;
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.primary};
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, border-color, box-shadow;

  :focus {
    border-bottom: 1px solid ${props => props.theme.colors.black};
    outline: none;
  }
`
const FormGroup = styled(Form.Control)`
  ${formProperties}
`
const FormGroupText = styled(Form.Control)`
  ${formProperties}
  padding: .375rem .75rem;
  height: 140px;
  resize: none;
`
const WarningMessage = styled.small`
  padding: 0.375rem 0.75rem;
  ${props => {
    if (props.pale) {
      return `
      color: ${props.theme.colors.pale};
      `
    } else if (props.success) {
      return `
        color: ${props.theme.colors.success};
    `
    } else {
      return `
        color: ${props.theme.colors.danger};
    `
    }
  }}
`
const ButtonSubmit = styled(Button)`
  padding: 0.45rem;
  margin-top: 0.45rem;
  margin-bottom: 0.45rem;
  max-width: 100%;
  width: 100%;
  letter-spacing: 4px;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.pale};
  border-color: ${props => props.theme.colors.primary};
  text-transform: uppercase;
  font-size: 20px;
  transition: 0.2s ease-in-out;
  transition-property: letter-spacing;
  :hover {
    background: ${props => props.theme.colors.secondary};
    letter-spacing: 10px;
  }
`
const FormCheck = styled(Form.Check)`
  ${props => {
    if (props.invisible) {
      return `
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
    `
    } else {
      return `
      opacity: 1;
      `
    }
  }}
`
