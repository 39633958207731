import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { SiFacebook, SiUnsplash, SiTwitter, SiInstagram } from "react-icons/si";

const index = () => {
  return (
    <Navbar>
      <Nav className="flex-row">
        <Nav.Link
          href="https://www.facebook.com/andrey.bondarenko"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SiFacebook size="1.3rem" />
        </Nav.Link>
        <Nav.Link
          href="https://twitter.com/andreybond"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SiTwitter size="1.3rem" />
        </Nav.Link>
        <Nav.Link
          href="https://instagram.com/andreybond"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SiInstagram size="1.3rem" />
        </Nav.Link>
        <Nav.Link
          href="https://unsplash.com/@andreybond"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SiUnsplash size="1.3rem" />
        </Nav.Link>
      </Nav>
    </Navbar>
  );
};

export default index;
