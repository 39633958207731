import styled, { css } from "styled-components"
import { Link } from "gatsby"
import ContainerRB from "react-bootstrap/Container"
import RowRB from "react-bootstrap/Row"
import ColRB from "react-bootstrap/Col"
import NavbarRB from "react-bootstrap/Navbar"
import NavRB from "react-bootstrap/Nav"

export const Container = styled(ContainerRB)`
  ${props => {
    if (props.secondary) {
      return `
      background: ${props.theme.colors.secondary};
      color: ${props.theme.colors.white};
      border-top: .45rem solid ${props => props.theme.colors.black};
      `
    } else {
      return `
        background: ${props => props.theme.colors.primary};
        color: ${props.theme.colors.black};
      `
    }
  }}
`
export const Row = styled(RowRB)``
export const Col = styled(ColRB)`
  ${props => {
    if (props.p5) {
      return `
      padding: .45rem .45rem .45rem .45rem;
    `
    } else if (props.p0) {
      return `
      padding: 0;
      margin: 0;
    `
    } else {
      return `
    `
    }
  }}
`
export const Navbar = styled(NavbarRB)`
  background: ${props => props.theme.colors.primary};
`
export const Nav = styled(NavRB)`
  display: flex;
`

export const Line = styled.hr`
  padding: 0;
  margin: 0;
  border: none;
  height: 5px;
  background-color: ${props => props.theme.colors.black};
`
export const ImageBed = styled.div`
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-box-shadow: 0px 9px 15px -6px rgba(0, 0, 0, 0.76);
  box-shadow: 0px 9px 15px -6px rgba(0, 0, 0, 0.76);

  ${props => {
    if (props.nohover) {
      return `
      :hover {
        opacity: 1;
      }     `
    } else {
      return `
      :hover {
        opacity: 0.8;
        cursor: pointer;
      }`
    }
  }}
`
export const Message = styled.section`
  font-family: ${props => props.theme.fonts.main}, sans-serif;
  line-height: 1.45rem;
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
`
const superLink = css`
  font-size: ${props => props.theme.navbar.fontsize};
  font-family: ${props => props.theme.fonts.secondary}, sans-serif;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0;
  transition: all 200ms ease-in;
  position: relative;
  padding-bottom: ${prop => (prop.footermenu ? ".45rem" : 0)};

  ${props => {
    if (props.muted) {
      return `
        color: ${props.theme.colors.muted};
    `
    } else if (props.white) {
      return `
        color: ${props.theme.colors.white};
    `
    } else {
      return `
        color: ${props.theme.colors.secondary};
    `
    }
  }}

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: ${props => props.theme.colors.pale};
    height: 1px;
    transition: all 0.4s ease-in;
  }
  :hover {
    text-decoration: none;
    ${props => {
      if (props.muted) {
        return `
          color: ${props.theme.colors.white};
      `
      } else if (props.white) {
        return `
          color: ${props.theme.colors.black};
      `
      } else {
        return `
          color: ${props.theme.colors.muted};
      `
      }
    }}
    ::after {
      content: "-";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 0%;
      content: ".";
      color: transparent;
      background: ${props => props.theme.colors.pale};
      height: 1px;
      transition: all 0.4s ease-in;
      width: 100%;
    }
  }
`
export const NavLink = styled(Link)`
  ${superLink}
`
export const ALink = styled.a`
  ${superLink}
`
export const StyledUl = styled.ul`
  padding: 0.25rem;
  display: contents;
`
export const StyledLi = styled.li`
  list-style-type: none;
  padding: 0.45rem;
`
export const FooterBlock = styled(Row)`
  padding: 1.45rem;
  align-content: space-between;
  text-transform: capitalize;
  width: ${props => props.theme.breakpoints.lg};
  color: ${props => props.theme.colors.pale};
  padding-top: 1.45rem;
  > div,
  a {
    color: ${props => props.theme.colors.pale};
    padding: 0.45rem;
  }
`
